var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLBASEINFO" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled && _vm.isOld && _vm.editable,
                        expression: "!disabled && isOld && editable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isComplete,
                      url: _vm.completeUrl,
                      param: _vm.cto,
                      mappingType: "PUT",
                      label: "LBLCOMPLETE",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completeCto,
                      btnCallback: _vm.completeCallback,
                    },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled && _vm.isOld && _vm.editable,
                        expression: "!disabled && isOld && editable",
                      },
                    ],
                    attrs: { label: "LBLREMOVE", icon: "delete_forever" },
                    on: { btnClicked: _vm.remove },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.disabled && _vm.editable,
                        expression: "!disabled && editable",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.cto,
                      mappingType: _vm.saveType,
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveCto,
                      btnCallback: _vm.saveCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    readonly: true,
                    editable: _vm.editable,
                    label: "관찰작업 / 공정",
                    name: "sopProcessName",
                  },
                  model: {
                    value: _vm.sopProcessName,
                    callback: function ($$v) {
                      _vm.sopProcessName = $$v
                    },
                    expression: "sopProcessName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    type: "date",
                    label: "관찰일",
                    name: "ctoDate",
                  },
                  model: {
                    value: _vm.cto.ctoDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "ctoDate", $$v)
                    },
                    expression: "cto.ctoDate",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    type: "time",
                    minuteStep: 10,
                    label: "소요시간",
                    name: "ctoTime",
                  },
                  model: {
                    value: _vm.cto.ctoTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "ctoTime", $$v)
                    },
                    expression: "cto.ctoTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    suffix: "명",
                    type: "number",
                    label: "관찰인원",
                    name: "observeCount",
                  },
                  model: {
                    value: _vm.cto.observeCount,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "observeCount", $$v)
                    },
                    expression: "cto.observeCount",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-multi-field", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.cto,
                    label: "관찰자",
                    type: "dept_user",
                    name: "observeUserId",
                  },
                  model: {
                    value: _vm.cto.observeUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "observeUserId", $$v)
                    },
                    expression: "cto.observeUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "작업구역",
                    name: "workArea",
                  },
                  model: {
                    value: _vm.cto.workArea,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "workArea", $$v)
                    },
                    expression: "cto.workArea",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: "",
                    disabled: _vm.disabled || _vm.isOld,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.cto.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "plantCd", $$v)
                    },
                    expression: "cto.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "총평" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    isArray: false,
                    codeGroupCd: "CTO_WORK_CHARACTER_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "workCharacteristics",
                    label: "작업특성",
                  },
                  model: {
                    value: _vm.cto.workCharacteristics,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "workCharacteristics", $$v)
                    },
                    expression: "cto.workCharacteristics",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    isArray: false,
                    codeGroupCd: "CTO_HUMAN_FACTOR_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "humanFactors",
                    label: "인적요소",
                  },
                  model: {
                    value: _vm.cto.humanFactors,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "humanFactors", $$v)
                    },
                    expression: "cto.humanFactors",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    isArray: false,
                    codeGroupCd: "CTO_CORE_ACTION_ITEMS_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "coreActionItems",
                    label: "핵심행동항목",
                  },
                  model: {
                    value: _vm.cto.coreActionItems,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "coreActionItems", $$v)
                    },
                    expression: "cto.coreActionItems",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "우선사항",
                    rows: 2,
                    name: "priorityContents",
                  },
                  model: {
                    value: _vm.cto.priorityContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "priorityContents", $$v)
                    },
                    expression: "cto.priorityContents",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "개선사항",
                    rows: 2,
                    name: "improveContents",
                  },
                  model: {
                    value: _vm.cto.improveContents,
                    callback: function ($$v) {
                      _vm.$set(_vm.cto, "improveContents", $$v)
                    },
                    expression: "cto.improveContents",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "activityTable",
          attrs: {
            title: "핵심행동 목록",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.cto.activityModels,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            noDataLabel: "핵심행동을 추가하세요.",
            hideBottom: true,
            editable: _vm.editable && !_vm.disabled,
            rowKey: "sopCtoActivityId",
            selection: "multiple",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addActivity },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  _vm.cto.activityModels.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLEXCEPT",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.removeActivity },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }